import React, { useState,useEffect } from 'react';
import { Card, Table, Drawer,Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { navigate } from 'gatsby';

const Pickup = ({PickupData}) => {

  const columns = [
    {
      title: 'EQUIPMENT',
      dataIndex: 'equipment_no',
      // width: 100,
      align: 'center',
      render: (_, root) => (
        <div style={{cursor:'pointer'}} onClick={()=>navigate(`/ticketdetails/${root.ticketId}`)}>{root.equipment_no}</div>
      ),
      // sorter: {
      //   compare: (a, b) => {
      //     a = a.equipment_no.toLowerCase ();
      //     b = b.equipment_no.toLowerCase ();
      //     return a > b ? -1 : b > a ? 1 : 0;
      //   },
      // },
    },
    {
      title: 'TICKET',
      dataIndex: 'ticket_details',
      // width: 100,
      align: 'center',
      render: (_, root) => (
        <div style={{cursor:'pointer'}} onClick={()=>navigate(`/ticketdetails/${root.ticketId}`)}>{root.ticket_details ? root.ticket_details: 'NA'}</div>
      ),
      // sorter: {
      //   compare: (a, b) => {
      //     a = a.ticket_details;
      //     b = b.ticket_details;
      //     return a > b ? -1 : b > a ? 1 : 0;
      //   },
      // },
    },
    {
      title: 'DAYS',
      dataIndex: 'days_in_yard',
      align: 'center',
      // width: 100,
      render: (_, root) => (
        <div style={{cursor:'pointer'}} onClick={()=>navigate(`/ticketdetails/${root.ticketId}`)}>{root.days_in_yard}</div>
      ),
      sorter: {
        compare: (a, b) => {
          a = a.days_in_yard;
          b = b.days_in_yard;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    
    
  ];
  
  const data = [];
  PickupData &&
    PickupData.map((item, index) => {
      data.push({
        key: index,
        equipment_no:item?.asset_details?.equipment_no,
        ticket_details: item?.ticket_details?.ticket_ref_id,
        days_in_yard: item?.days_in_yard,  
        ticketId:item?.ticket_details?.ticket_id
      });
    });
  return (
    <Card>
      {data&&<Table
        columns={columns}
        dataSource={data}
        scroll={{ y: 200 }}
        pagination={false}
      />}
      <style>{}</style>
    </Card>
  );
};
export default Pickup;
