import React, { useState, useEffect } from "react"
import Chart from "../../../components/Charts/Chart"
import _get from "lodash/get"
import { COLOR_1, COLOR_2, COLOR_4 } from "../../../Constants/ChartConstant"
import { IndustryType, RAIL_CAR } from "../../../utils/helper"
const Doughnut = ({ data }) => {
  const [state, setState] = useState({ data: [] })
  useEffect(() => {
    if (data) {
      setState({ ...state, data: data })
    }
  }, [data])

  // let chartData = {
  //   labels: ['Closed', 'Complete', 'In Wash', 'Pre Wash'],
  //   datasets: [
  //     {
  //       data: state.data,
  //       backgroundColor: [COLOR_1, COLOR_4, COLOR_2],
  //       pointBackgroundColor: [COLOR_1, COLOR_4, COLOR_2],
  //     }
  //   ]
  // }

  let chartData = {
    labels:
      IndustryType() == RAIL_CAR
        ? ["Car Spotted", "In Process", "Completed"]
        : ["Inspection Pending", "In Wash", "Complete"],
    datasets: [
      {
        data: state.data,
        backgroundColor: ["#48A4F4", "#5BB42C", "#F48951"],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }

  return (
    state.data.length !== 0 && (
      <Chart
        type="doughnut"
        chartData={chartData}
        location="Wash Status"
        legendPosition="bottom"
      />
    )
  )
  // return<div>djk</div>
}
export default Doughnut
