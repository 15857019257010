import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import {
  getDashboardCount,
  getWashStatus,
  getAlertStatus,
  getMonthlyEstimate,
  getMonthlyInvoice,
  getMonthlyTicket,
  getEquipmentPickup,
  getTicketAwaiting,
} from "../../actions/dashboardAction"
import { connect } from "react-redux"
import { getAttentionList } from "../../actions/attentionAction"
import AppLayout from "../../components/layout"
import InColumn from "../../components/InputField/InColumn"
import { Routes } from "../../utils/route"
import { Spin, Row, Col } from "antd"
import TicketList from "./Component/TicketList"
import { Line } from "react-chartjs-2"
import Doughnut from "./Graph/doughnut"
import Product from "./Component/Product"
import PickUp from "./Component/PickUp"
import VerticalChart from "./Graph/vertical-chart"
import API from "../../api"
import MonthlyEstimate from "./Graph/monthlyEstimate"
import MonthlyTicket from "./Graph/monthlyTicketReport"
import MonthlyInvoice from "./Graph/monthlyInvoiceReport"
import RevenuesGenerated from "./Graph/revenuesGenerated"
import PageHeader from "../../components/pageHeader/PageHeader"

const Dashboard = props => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const callback = val => {
      if (val === true) {
        setLoading(false)
        props.getDashboardCount()
        props.getWashStatus()
        props.getAlertStatus()
        // props.getMonthlyEstimate();
        // props.getMonthlyInvoice();
        // props.getMonthlyTicket();
        props.getEquipmentPickup()
        props.getTicketAwaiting()
        props.getAttentionList()
      }
    }
    Routes("common", callback)
  }, [])
  if (!loading) {
    return (
      <AppLayout>
        <PageHeader title="my dashboard" />
        <Row>
          <Col xs={{ span: 24 }} xl={{ span: 16 }}>
            <InColumn
              data={props?.dashboardCount}
              washdata={props?.washStatus}
            />
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 8 }}>
            <Doughnut data={props?.washStatus} location="Wash Data" />
          </Col>

          <Col xs={{ span: 24 }} xl={{ span: 16 }}>
            <RevenuesGenerated
              type="bar"
              location="Top 5 Revenues Generated By Customers"
            />
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 8 }}>
            <VerticalChart
              data={props?.alertStatus}
              type="bar"
              location="Count By 10 Days Alert"
            />
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 24 }}>
            <MonthlyInvoice
              data={props?.monthlyInvoice}
              type="line"
              location="Monthly Invoice"
            />
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
            <MonthlyEstimate
              data={props?.monthlyEstimate}
              type="bar"
              location="Monthly Estimate"
            />
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
            <MonthlyTicket
              data={props?.monthlyTicket}
              type="bar"
              location="Monthly Ticket"
            />
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
            <div className="col-border">
              <div className="col-padding">
                <h3 className="card-title-table">Equipment Awaiting Pick up</h3>
                <PickUp PickupData={props?.equipmentAwaiting} />
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
            <div className="col-border">
              <div className="col-padding">
                <h3 className="card-title-table">Ticket Awaiting Approval</h3>
                <TicketList ticketData={props.ticketAwaiting} />
              </div>
            </div>
          </Col>
        </Row>
        <style>{`
			 .dashboard-chart-height{
				height:350px;
                margin: 5px;
			} 
			.dashboard-chart-height-doughnut{
                margin: 5px;
			}
			// .chart_container{
			// 	padding-right: 10px;
			// 	padding-top: 10px;
			// }
			.card-title{
				color: #2E384D;
				opacity: 1;
				font-weight: bold;
				font-size: 18px;
				margin-bottom: 45px;
                margin-left: 5px;
			}
			.card-title-doughnut{
				color: #2E384D;
				opacity: 1;
				font-weight: bold;
				font-size: 18px;
				margin-bottom: 7px;
                margin-left: 5px;
			}
			.card-title-table{
				color: #2E384D;
				opacity: 1;
				font-weight: bold;
				font-size: 18px;
                margin-left: 5px;
				margin-bottom: 25px;
			}
			.col-border{
				width:100%;
				height: 100%;
				// background:#fafafb;
				padding:10px;	
			}
			.col-padding{
				border-radius:6px;
				background:white;
				padding: 10px 10px 10px 14px;
				height: 100%;
			}
			.select_days select{
				margin-top: 5px;
                color: #2E384D;
                background:#F6F6F6;
                border: 0px;
                outline: 0px;
				margin-right: 6px;
			}
			 `}</style>
      </AppLayout>
    )
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.myYard.loading,
  dashboardCount: state.dashboard.dashboardCount,
  washStatus: state.dashboard.washStatus,
  alertStatus: state.dashboard.alertStatus,
  monthlyEstimate: state.dashboard.monthlyEstimate,
  monthlyInvoice: state.dashboard.monthlyInvoice,
  monthlyTicket: state.dashboard.monthlyTicket,
  equipmentAwaiting: state.dashboard.equipmentAwaiting,
  ticketAwaiting: state.dashboard.ticketAwaiting,
})

export default connect(mapStateToProps, {
  getDashboardCount,
  getWashStatus,
  getAlertStatus,
  getMonthlyEstimate,
  getMonthlyInvoice,
  getMonthlyTicket,
  getEquipmentPickup,
  getTicketAwaiting,
  getAttentionList,
})(Dashboard)
