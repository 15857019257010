import React,{useState,useEffect} from 'react';
import Chart from '../../../components/Charts/Chart';
import _get from 'lodash/get';
import {ChartMonthlyColor} from '../../../utils/helper' 
import { useDispatch } from 'react-redux';
import { getMonthlyInvoice } from '../../../actions/dashboardAction';
const MonthlyInvoice=({type, location, labels,data })=>{

  const dispatch = useDispatch();

  useEffect(()=>{
  
    dispatch(getMonthlyInvoice())
  },[])
        let arr=[]
        const  label=[]
        let  chartData = {
            labels: label,
            datasets:[ 
              {
                label: 'Invoice',
                data: arr,
                // backgroundColor: ChartMonthlyColor,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderColor:'#48A4F4',
                borderWidth: 2,
                // borderDash: [10,5],
                
              }],
            
          };

          data&&data.map((item)=>{
            label.push(item.month)
                arr.push(item.total)
          }) 

          const dropDownSelect=(e)=>{
            dispatch(getMonthlyInvoice(e.target.value))
          }

          const days=[
            {value:7,displayValue:'Last 7 Days'},
            {value:15,displayValue:'Last 15 Days'},
            {value:30,displayValue:'Last 30 Days'},
            {value:90,displayValue:'Last 90 Days'},
          ]
          
          const form =
           <select
           virtual={false}
                      allowClear={false}
                      placeholder={`SELECT DAYS`}
                      defaultValue={7}
                     // onChange={(e) => dropDownSelect(e)}
                    >
                      {days.map((item) => (
                          <option value={item.value}>
                            {item.displayValue}
                          </option>
                        ))}
                    </select>
        
      
        return (<>
         {data&&data.length!==0&&
            <Chart
            //form={form}
            tooltip={true}
              type={type}
              chartData={chartData}
              location={location}
              legendPosition="bottom"
            />}
        </>);
        // return<div>djk</div>
      
}
export default MonthlyInvoice;
