import { types } from './type';
import API from '../api';
import { message } from 'antd';
const { attentionList, AcceptRejectAction } = API;

export const getAttentionList = () => async (dispatch) => {
  //   dispatch ({type: types.SHOW_LOADING, loading: true});

  attentionList()
    .then((res) => {
      if (res.data.success) {
        message.success({ content: `LIST FETCHED`, duration: 2 });
        if(res.data.data.length!==0){localStorage.setItem('attention',res.data.data.length)}
        dispatch({
          type: types.ATTENTION_LIST,
          loading: false,
          data: res.data.data,
          count:res.data.total,
          
        });
      } else {
        console.log('Error in attentionList', res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const PerformAttentionAction = (data) => async (dispatch) => {
  dispatch({ type: types.SHOW_LOADING, loading: true });

  AcceptRejectAction(data)
    .then((res) => {
      if (res.data.success) {
        message.success({
          content: `Action Performed Successfully`,
          duration: 2,
        });
        dispatch({
          type: types.REMOVE_FROM_ATTENTION_LIST,
          loading: false,
          id: data.id,
        });
        var attention = (parseInt(localStorage.getItem('attention'))-1);
        if(attention===0){
          localStorage.removeItem('attention')
        }else{
          localStorage.setItem("attention",attention.toString());
        }
      } else {
        message.error({
          content: `NOT ABLE TO PERFORM THE ACTION`,
          duration: 2,
        });
      }
    })
    .catch((err) => {
      message.error({ content: `NOT ABLE TO PERFORM THE ACTION`, duration: 2 });
    });
};
