import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import './index.css';
import ImgTicket from '../../images/new/open_wash_ticket_icon.svg';
import ImgEstimate from '../../images/new/MTD estimate_icon.svg';
import ImgInvoices from '../../images/new/MTD_invoices_icon.svg';
import Doughnut from "../../pages/dashboard/Graph/doughnut";

const InColumn = ({ data, washdata }) => {
  const [count, setCount] = useState({ openWashCount: 0, estimateAmt: 0, totalEstimate: 0, totalInyard: 0, totalInvoiceAmt: 0, totalInvoices: 0 });

  useEffect(() => {
    if (data) {
      setCount({ ...count, openWashCount: data.open_wash_count, estimateAmt: data.total_estimated_amount, totalEstimate: data.total_estimation_given, totalInyard: data.total_in_yard, totalInvoiceAmt: data.total_invoice_amount, totalInvoices: data.total_invoices })
    }
  }, [data])
  return (
    // <div style={{ background: "#fafafb", padding: "30px" }} >
    //   <Row gutter={16}>
    //     <Col span={8} >
    //       <Card className="boxShadow" title="Open Wash Tickets" bordered={false} >
    //         <h2>{count.openWashCount}</h2>
    //       </Card>
    //     </Col>
    //     <Col span={8}>
    //       <Card className="boxShadow" title="MTD Estimate" bordered={false} >
    //         <h2>{count.totalEstimate}</h2>
    //       </Card>
    //     </Col>
    //     <Col span={8}>
    //       <Card className="boxShadow" title="MTD Invoices" bordered={false} >
    //         <h2>{count.totalInvoices}</h2>
    //       </Card>
    //     </Col>

    //   </Row>
    //   <style>{`
    //       .boxShadow{
    //         box-shadow: 5px 5px 6px -7px rgba(0,0,0,0.75);
    //       }
    //     `}</style>
    // </div>
    <>
      <div className="incolumn_container">
        <div className="incolumn_left">
          <div className="incolumn_heading">
            <h2>Statistics</h2>
          </div>
          <Row gutter={24} style={{marginTop:28}}>
            <Col span={8}>
            <div className="d-flex">
              <div className="incolumn_img">
                <img src={ImgTicket} alt="Open Wash Tickets" />
              </div>
              <div className="incolumn_content">
                <h4>Open Wash Tickets</h4>
                <p>{count.openWashCount}</p>
              </div>
              </div>
            </Col>


            <Col span={8}>
            <div className="d-flex">
              <div className="incolumn_img">
                <img src={ImgEstimate} alt="MTD Estimate" />
              </div>
              <div className="incolumn_content">
                <h4>MTD Estimate</h4>
                <p>{count.totalEstimate}</p>
              </div>
              </div>
            </Col>


            <Col span={8}>
            <div className="d-flex">
              <div className="incolumn_img">
                <img src={ImgInvoices} alt="MTD Invoices" />
              </div>
              <div className="incolumn_content">
                <h4>MTD Invoices</h4>
                <p>{count.totalInvoices}</p>
              </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* <div className="incolumn_right">
        <Col span={8}>
            <Doughnut data={washdata} />  
            </Col>        
        </div> */}

      </div>
    </>
  );
}

export default InColumn;
