import React,{useState,useEffect} from 'react';
import Chart from '../../../components/Charts/Chart';
import API from '../../../api'
import _get from 'lodash/get';
import {Select } from 'antd';
import {ChartMonthlyColor} from '../../../utils/helper' 
import {
	getRevenuesGenerated
	  } from '../../../actions/dashboardAction';
	  import { connect } from 'react-redux';
const RevenuesGenerated=({type, location, labels,revenueGenerated,getRevenuesGenerated })=>{
    const { Option } = Select;
    const[rev,setRev]=useState([])
    const [stateBtn,setStateBtn]=useState(7)
        useEffect(()=>{
          getRevenuesGenerated(7)
        },[])

        useEffect(()=>{
        setRev(revenueGenerated)
        },[revenueGenerated])
        const dropDownSelect=(value)=>{
          getRevenuesGenerated(value)
        }
        let arr=[]
        const  label=[]
        let  chartData = {
            labels: label,
            datasets:[ 
                
              {
                label: 'Revenue',
                data: arr,
                // backgroundColor: ChartMonthlyColor,
                backgroundColor: [
                  '#FFEFE5',
                  '#FFE3D1',
                  '#FFC59E',
                  '#FFB07B',
                  '#FD8F46'
                ],
                borderWidth: 2,
              }],
            
          };

          rev&&rev.map((item)=>{

            label.push(item.customer_details.name )
                arr.push(item.total_amount)
          }) 
          
          const buttonHnadler=(id)=>{
            setStateBtn(id)
            getRevenuesGenerated(id)
        }
          const days=[
              {value:7,displayValue:'7 Days'},
              {value:15,displayValue:'15 Days'},
              {value:30,displayValue:'30 Days'},
              {value:90,displayValue:'90 Days'},
            ]
          
      const form =
      days.map((item) => (
        <button onClick={()=>buttonHnadler(item.value)}
        style={{background:item.value===stateBtn?'#2E384D':"#F6F6F6",color:item.value===stateBtn?'#fff':"#2E384D",
        marginRight: "1px",padding: "0px 4px",border: "none",marginTop: "6px",letterSpacing: "0px",fontSize: "12px",cursor:"pointer" }} >
          {item.displayValue}
        </button>
      ))
          //  <Select
          //  virtual={false}
          //             allowClear={false}
          //             placeholder={`SELECT DAYS`}
          //             defaultValue={7}
          //             onChange={(e) => dropDownSelect(e)}
          //           >
          //             {days.map((item) => (
          //                 <Option value={item.value}>
          //                   {item.displayValue}
          //                 </Option>
          //               ))}
          //           </Select>
        return (
         <> 
        { rev&&rev.length!==0 &&<Chart
            form={form}
            tooltip={true}
              type={type}
              chartData={chartData}
              location={location}
              legendPosition="bottom"
            />}
</>
        );
        // return<div>djk</div>
      
}
const mapStateToProps = (state) => ({
  revenueGenerated:state.dashboard.revenueGenerated

  });
export default connect(mapStateToProps, {
  getRevenuesGenerated
  })(RevenuesGenerated);